<template>
  <b-row>
    <b-col
      xs="12"
      md="4"
    >
      <customer-card
        v-if="alert.id_com_customer"
        :id_com_customer="alert.id_com_customer"
      />
    </b-col>
    <b-col>
      <b-card title="Hatırlatma">
        <b-list-group>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Hatırlatma Tarihi
            </div>
            <div>
              {{ alert.alert_date? moment(alert.alert_date).format('DD.MM.YYYY') : '-' }}
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="text-primary font-weight-bolder">
              Hatırlatma Notu
            </div>
            <div v-html="alert.alert_notes" />
          </b-list-group-item>
        </b-list-group>
        <b-button
          :variant="(alert.alert_status !== '1')? 'success' : 'danger'"
          class="mt-1"
          @click="changeStatus"
        >
          {{ alert.alert_status !== '1'? 'Hatırlamayı Kapat' : 'Hatırlamayı Aç' }}
        </b-button>
      </b-card>
    </b-col>
  </b-row>
</template>
<script>
import {
  BRow, BCol, BCard, BListGroup, BListGroupItem, BButton,
} from 'bootstrap-vue'
// eslint-disable-next-line import/extensions
import CustomerCard from '@/views/Alerts/components/CustomerCard'

export default {
  name: 'Add',
  components: {
    BRow,
    BCol,
    BCard,
    BListGroup,
    BListGroupItem,
    BButton,
    CustomerCard,
  },
  computed: {
    alert() {
      return this.$store.getters['alerts/getAlert']
    },
  },
  created() {
    this.getAlert()
  },
  methods: {
    getAlert() {
      this.$store.dispatch('alerts/alertView', this.$route.params.id)
    },
    changeStatus() {
      let alertStatus = '1'
      if (this.alert.alert_status === '1') {
        alertStatus = '0'
      }
      if (this.alert.alert_status === '0') {
        alertStatus = '1'
      }
      this.$store.dispatch('alerts/alertSave', {
        id: this.alert.id,
        alert_status: alertStatus,
      })
      this.getAlert()
    },
  },
}
</script>

<style scoped>

</style>
